import React from "react";
import { graphql } from "gatsby";
import { HelmetDatoCms } from "gatsby-source-datocms";
import Layout from "../components/Layout";
import BreadCrumps from "../ui/breadCrumps/BreadCrumps";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export default function ClubPage({ data }) {
  const filterCoaches = (array, filterValue) => {
    let filteredArray = array.filter(
      ({ node: coach }) => coach.staffMember == filterValue
    );

    let arrayData = filteredArray ? filteredArray : [];
    return arrayData;
  };

  const isstaffMemberList = filterCoaches(data.coaches.edges, true);
  const isNotstaffMemberList = filterCoaches(data.coaches.edges, false);

  return (
    <>
      <HelmetDatoCms seo={data.club.seo} favicon={data.site.favicon} />
      <Layout>
        <BreadCrumps title={data.club.title} />
        <section className="single-section">
          <div>
            <h1 style={{ marginBottom: `18px` }}>{data.club.title}</h1>
            <strong>AДРЕСА : {data.club.adress}</strong>
          </div>
        </section>
        {/* <section className="single-section" id="gallery">
                <div>
                        <h2 style={{textTransform: `uppercase`}}>Галерея</h2>
                        <br/>
                        { data.club.clubgallery.map((card, index)=>{
                                return (
                                    <div key={index} style={{maxWidth: `300px`, margin: `20px`}}>
                                        <GatsbyImage image={getImage(card.gatsbyImageData)} alt={"image"} />
                                    </div>
                                )
                            })
                        }
                </div>
            </section> */}
        <section className="single-section" id="gallery">
          <div>
            <h2 style={{ textTransform: `uppercase` }}>
              {data.club.sceduletitle}
            </h2>

            {data.club.sceduletitle.sceduledescription ? (
              <p>{data.club.sceduletitle.sceduledescription}</p>
            ) : null}
            <br />
            <p>
              Уточнюйте інформацію на рецпеції або через дірект.
            </p>
              
          {/*               
            {
                data.club.sceduleimage ? (
                    <GatsbyImage
                        layout="constrained"
                        image={getImage(data.club.sceduleimage.gatsbyImageData)}
                        alt={"Групові заняття"}
                    />
                ) 
                : null
             
            } */}
          </div>
        </section>
  
      </Layout>
    </>
  );
}

export const query = graphql`
  query ClubBySlug($slug: String) {
    site: datoCmsSite {
      favicon: faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
    club: datoCmsClub(slug: { eq: $slug }) {
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      adress
      sceduletitle
      sceduledescription
      sceduleimage {
        gatsbyImageData
      }
    }
    coaches: allDatoCmsCoach(
      filter: { club: { elemMatch: { slug: { eq: $slug } } } }
    ) {
      edges {
        node {
          title
          details
          staffMember
          coverimage {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
